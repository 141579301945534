* {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Open Sans",
    sans-serif;
    font-size: 20px;
    color: #444;
    text-align: center;
    line-height: 1.5;
  }
  
  header h2{
    padding: 45px 15px;
  }
  
  section {
    min-height: 720px;
    padding: 30px 15px;
    background-color: #8AAAE5;
  }
  
  section:nth-child(even) {
    background-color: #ffffff;
  }
  
  p {
    margin: 0 auto;
    max-width: 650px;
  }
  
  p + p {
    margin-top: 15px;
  }
  