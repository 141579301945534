.Header {
  height: 48px;
  display: flex;
  align-items: normal;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0px 5px;
}

.Header h1 {
  margin: 0 25px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 400;
  color: #8aaae5;
}

.Header h1 a {
  color: #8aaae5;
  text-decoration: none;
}

.Header a {
  color: #444;
  text-decoration: none;
}

.Header__not-logged-in {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.Header__not-logged-in a:last-child {
  background: rgb(99, 99, 99);
  color: white;
  padding: 0px 5px;
  border-radius: 15px;
  margin-left: 10px;
}

.Header__logged-in {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.Header__logged-in a:last-child {
  background: rgb(99, 99, 99);
  color: white;
  padding: 0px 5px;
  border-radius: 15px;
  margin-left: 10px;
}

@media screen and (min-width: 450px) {
  .Header {
    align-items: baseline;
  }

  .Header h1 {
    font-size: 28pt;
    color: #8aaae5;
  }
}

@media screen and (max-width: 490px) {
  .Header__tagline--wide {
    display: none;
  }

  .Header__tagline--narrow {
    display: inherit;
  }
}
