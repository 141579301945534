.App {
  height: 100vh;

  margin: 0 auto;
  background-color: #8aaae5;
}

.App__main {
  height: calc(100vh - 70px);
  margin-top: 10px;
  padding-bottom: 40px;
}
