* {
  box-sizing: border-box;
}

body {
  padding-top: 0;
  font-family: Helvetica Neue, Helvetica;
  background: #f7f7f7;
}

h1 {
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 5px;
}

form {
  padding: 0 40px;
}

form input.title {
  width: 30%;
}

form input {
  display: block;
  width: 100%;
  font-size: 20px;
  padding: 5px 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
}

form input.error {
  border-color: red;
}

form button {
  display: inline-block;
  width: 55%;
  appearance: none;
  text-align: center;
  border-radius: 5px;
  background-color: #8aaae5;
  color: #fff;
  border: none;
  font-size: 16px;
  height: 40px;
  margin-top: 30px;
}

form button:hover {
  background-color: rgb(107, 131, 177);
}

form button:disabled {
  background-color: #dbf99f;
  color: #333;
}

form button.small {
  display: inline-block;
  width: auto;
  padding: 0 20px;
  background-color: #777;
  font-size: 14px;
}

form button.small:hover {
  background-color: #999;
}

div #blessing {
  display: block;
  padding: 5px 10px;
  color: #ced1f0;
  font-family: 'Georgia', serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
  text-shadow: #2a8d7b -1px -1px;
}
.listItem {
  display: flex;
  direction: row;
  align-items: center;
}

.listItem button {
  margin: 0;
  margin-left: 10px;
}
